<template>
  <div class="block">
    <el-carousel height="100vh" arrow="always">
      <el-carousel-item v-for="item in swiperlist" :key="item.id">
        <img class="swiper-img" :src="item.src" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      swiperlist: [
        {
          src: 'https://zmt-source.maolinbaoxian.com/upload/website/swiper.png',
          id: '2344345'
        },
        {
          src: 'https://zmt-source.maolinbaoxian.com/upload/website/swiper1.png',
          id: '5672346'
        }
      ]
    }
  }
};
</script>

<style lang="scss" scoped>
.block {
  // height: 8.97rem;
  font-size: 0.5rem;
  position: relative;
}
.swiper-text {
  width: 100%;
  position: absolute;
  bottom: 2.09rem;
  color: #fff;
  z-index: 9;
  font-size: 0.72rem;
  img {
    margin-right: 0.29rem;
  }
}
.swiper-eng {
  font-size: 0.2rem;
  color: #c2c2c2;
  font-family: Ebrima;
  font-weight: 600;

}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.swiper-img {
  width: 100%;
  height: 100%;
}
.el-carousel__arrow {
  height: 0.5rem !important;
  width: 0.5rem !important;
  font-size: 0.2rem !important;
}
</style>