import Vue from 'vue'
import App from './App.vue'
import router from './router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "@/assets/style/flex.scss"
import media from './media'

Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.media = media
new Vue({
  render: h => h(App),
  router
}).$mount('#app')
