<template>
    <!-- 公司首页 -->
    <div class="home">
      <Swiper />
      <div class="plan df jc fc">
        <div class="show df fc">
        <div class="title" style="padding: 0.8rem 0 0.4rem">合作大V|价值观</div>
          <div class="df">
            <img class="show-img" src="https://zmt-source.maolinbaoxian.com/upload/website/showv.jpg" alt="" />
            <div class="df jc fx">
              <div class="show-box df fc aie">
                <div class="df jc aic">
                  <img class="logo-img" src="https://zmt-source.maolinbaoxian.com/upload/website/logo.png" alt="" />
                </div>
                <div class="df je">
                  <div class="show-title">
                    客户至上，理赔至上，服务至上，不误导、不忽悠、诚信经营
                    <!-- 让每一位客户风险来临的时候，都可以及时拿到理赔金 -->
                  </div>
                </div>
                <div class="show-text">
                  813是“保一生”的谐音：寓意美好，过目难忘，深入人心，值得信赖；将每年8.13打造为年度直播保险节，建立913直播保险基地：孵化、选品、客服全产业。
                </div>
                <div class="show-more df je aic">
                  <div class="show-more-text df jc aic" @click="goCompany">
                    了解更多
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="show-list fx df">
            <div
              class="df fc jb aic fx"
              v-for="(item, index) in arr"
              :key="item.num"
            >
              <img :src="item.img" alt="" style="width: 0.9rem;" />
              <div class="show-list-text">{{ item.text }}</div>
              <div class="show-list-num">
                <span>{{ item.num }}</span>
                <span class="unit" v-if="index === 0">万人</span>
                <span class="unit" v-else-if="index === 2">款</span>
                <span class="unit" v-else-if="index === 1">%</span>
                <span class="unit" v-else>余家</span>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="newcenter">
        <div class="title">为什么是我们 Why Us</div>
        <div class="desc">
          <span
            >行业资深人士在细分领域的成功经验=>独一无二的资源优势=>流量红利=>参与直播保险标准制定=>主动拥抱监管=>系统布局未来战略，拥有大格局</span
          >
        </div>
        <Newsw
          :swiperda="swiperda"
          :newswshow="newswshow"
          @addItem="addItem"
        />
        <!-- <div class="allnews" @click="allnews">全部新闻</div> -->
      </div>
    </div>
</template>
  
<script>
  import Swiper from "../components/swiper.vue";
  import Newsw from "../components/newswiper.vue";
  export default {
    name: "Home",
    components: {
      Swiper,
      Newsw,
    },
    data() {
      return {
        newswshow: false,
        arr: [
          {
            text: "承保用户",
            img: 'https://zmt-source.maolinbaoxian.com/upload/website/setUp.png',
            num: "100",
          },
          {
            text: "投诉率",
            img: 'https://zmt-source.maolinbaoxian.com/upload/website/patent.png',
            num: "0",
          },
          {
            text: "在售产品",
            img: 'https://zmt-source.maolinbaoxian.com/upload/website/knowledge.png',
            num: "28",
          },
          {
            text: "合作公司",
            img: 'https://zmt-source.maolinbaoxian.com/upload/website/security.png',
            num: "10",
          },
        ],
        swiperda: [
          {
            id: "qwe",
            arr: [
              {
                img: [
                  "https://zmt-source.maolinbaoxian.com/upload/website/adrisShow.jpg",
                  // require("@/assets/img/show.jpg"),
                  // require("@/assets/img/show1.jpg"),
                  // require("@/assets/img/show2.jpg"),
                ],
                id: "54dpoiu",
                title: "2021年度数字产业创新品牌",
                text: [],
                date: "2021年",
              },
              {
                img: [
                  "https://zmt-source.maolinbaoxian.com/upload/website/school.png",
                  // require("@/assets/img/school1.jpg"),
                  // require("@/assets/img/school2.jpg"),
                  // require("@/assets/img/school3.jpg"),
                ],
                id: "54oiuye",
                title: "2022年度最具成长力项目",
                text: [],
                date: "2021-01-14",
              },
              {
                img: [
                  "https://zmt-source.maolinbaoxian.com/upload/website/eng.jpg",
                  // require("@/assets/img/work2.jpg"),
                  // require("@/assets/img/work3.jpg"),
                  // require("@/assets/img/work4.jpg"),
                ],
                id: "54kdhin",
                title:
                  "快手商城优秀合作伙伴",
                date: "2021-01-21",
                text: [],
              },
            ],
          },
        ],
      };
    },
    mounted() {
      if (this.swiperda.length > 1) {
        this.newswshow = true;
      }
    },
    methods: {
      allnews() {
        this.$router.push("/news");
      },
      addItem() {
        sessionStorage.setItem("url", JSON.stringify("home"));
      },
      goCompany() {
        return false
        this.$router.push("/Contact");
      },
      gomore(item) {
        sessionStorage.setItem("url", JSON.stringify("home"));
        this.$router.push({
          name: "ProductDetails",
          query: {
            data: item,
          },
        });
        localStorage.setItem("obj", JSON.stringify(item));
      },
      allnews() {
        this.$router.push("/news");
      },
    },
  };
</script>
<style lang="scss" scoped>
// @import '../assets/style/flex.css';
  .title {
      font-size: 0.48rem;
      color: #333;
      font-weight: 600;
      text-align: center;
      letter-spacing: 0.1rem;
    }
  .newcenter {
    padding: 0.8rem 1.6rem 0.2rem 1.6rem;
    background-color: #f6f6f6;
  
    
  
    .desc {
      font-size: 0.18rem;
      text-align: center;
      margin: 0.2rem auto 0.48rem auto;
      color: #999;
      width: 43%;
  
      span {
        line-height: 0.32rem;
      }
    }
  }
  
  .allnews {
    font-size: 0.18rem;
    cursor: pointer;
    @include wihe(3.35rem, 0.66rem);
    margin: 0.84rem auto 0.04rem auto;
    @include dif(center, center); 
    border: 1px solid #cfcfcf;
    white-space: nowrap;
  }
  
  .content {
    width: 100%;
    height: 100%;
    min-height: 100vh;
  }
  
  .navBar {
    width: 100%;
    position: fixed;
    background: #fff;
    top: 0;
    z-index: 1;
  }
  
  .plan {
    background: #f6f6f6;
    font-size: 0.48rem;
  
    .plan-title {
      color: #333;
      padding: 1.02rem 0 0.25rem;
      font-weight: 600;
    }
  
    .plan-text {
      color: #999999;
      font-size: 0.18rem;
      padding-bottom: 0.55rem;
    }
  
    .plan-item {
      max-width: 4.39rem;
      background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5),
        #fff
      );
      // padding: 0 0.44rem;
      padding: 0 1.5rem;
  
      &:hover {
        transform: scale(1.05);
        box-shadow: 0 0.03rem 0.07rem rgb(228, 228, 228);
      }
  
      img {
        width: 1.9rem;
      }
    }
  
    .plan-img-box {
      padding: 0 1.68rem;
      font-size: 0.28rem;
      margin-bottom: 1.23rem;
    }
  
    .plan-introduce {
      width: 2.56rem;
      font-size: 0.16rem;
      color: #aaaaaa;
      margin: 0.17rem 0 0.53rem;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  
    .plan-more {
      border: 0.015rem solid #006895;
      color: #006895;
      font-size: 0.14rem;
      padding: 0.13rem 0.78rem 0.15rem 00.79rem;
    }
  
    .plan-more:hover {
      color: #fff;
      background: #da251d;
      border: 0.015rem solid #da251d;
      cursor: pointer;
    }
  
    .plan-more-box {
      padding-bottom: 0.49rem;
    }
  }
  
  .show {
    padding-top: 0.1rem;
    background: #fff;
    font-size: 0.34rem;
    padding-bottom: 1.22rem;
  
    .show-box {
      max-width: 5.01rem;
    }
  
    .show-img {
      width: 10.85rem;
      border: none;
    }
  
    .logo-img {
      width: 3.2rem;
      padding: 1rem 0 0.33rem;
    }
  
    .show-title {
      max-width: 4.62rem;
      padding-bottom: 0.32rem;
    }
  
    .show-text {
      font-size: 0.18rem;
      color: #999999;
      padding-bottom: 0.56rem;
    }
  
    .show-more {
      color: #fff;
      text-align: center;
      font-size: 0.14rem;
    }
  
    .show-more-text {
      width: 2.66rem;
      height: 0.48rem;
      color: #006895;
      border: 0.01rem solid #006895;
      cursor: pointer;
    }
  
    .show-more-text:hover {
      border: 0.01rem #da251d solid;
      background: #da251d;
      color: #fff;
    }
  
    .show-list {
      padding-top: 1.22rem;
    }
  
    .show-list-text {
      font-size: 0.19rem;
      padding: 0.25rem 0 0.07rem;
    }
  
    .show-list-num {
      color: #da251d;
      font-size: 0.55rem;
      position: relative;
    }
  
    .unit {
      font-size: 0.2rem;
      color: #00205b;
      position: absolute;
      right: -0.5rem;
      top: 0.4rem;
    }
  }
  
  .el-carousel-item {
    display: flex;
    flex-wrap: wrap;
  }
</style>
  