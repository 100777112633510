<template>
  <div id="app">
    <!-- <Nav :checks="checks" @skip="skip" id="navhi" />
    <Footer @pro="pro" /> -->
    <Nav :checks="checks" id="navhi" />
    <router-view></router-view>
    <Footer/>
  </div>
</template>

<script>
import Nav from "./components/topnav.vue";
import Footer from "./components/footer.vue";
import Home from './views/Home.vue'
import Product from './views/Product.vue'

export default {
  name: 'App',
  components: {
    Home,
    Nav,
    Footer,
    Product
  },
  data() {
    return {
      property: "value",
      checks: 0,
    };
  },
}
</script>
<style>
*{
  margin: 0;
  padding: 0;
}
</style>

