<template>
    <div class="content center">
        <div class="option">
            <div class="optionnav"><span class="left">条件选择</span><span @click="clear()" class="right">清除选项</span></div>
            <div class="optioncontent">
                <div class="item">
                    <div class="title">保险险种:</div>
                    <div class="sortList"><span @click="filterInsuranceType('全部')" :class="checkInsuranceType==='全部'? 'nowItem':''">全部</span>
                        <span @click="filterInsuranceType(insuranceType)" :class="checkInsuranceType===insuranceType? 'nowItem':''" v-for="insuranceType in insuranceTypes">{{ insuranceType }}</span>
                    </div>

                </div>
                <div class="item">
                    <div class="title">保险公司:</div>
                    <div class="sortList"><span @click="filterInsuranceCompany('全部')" :class="checkInsuranceCompany==='全部'? 'nowItem':''">全部</span>
                        <span @click="filterInsuranceCompany(insuranceCompany)" :class="checkInsuranceCompany===insuranceCompany? 'nowItem':''"  v-for="insuranceCompany in insuranceCompanys">{{ insuranceCompany }}</span>
                    </div>

                </div>
                <div class="item">
                    <div class="title">产品类型:</div>
                    <div class="sortList"><span @click="filterProductType('全部')" :class="checkProductType==='全部'? 'nowItem':''">全部</span>
                        <span @click="filterProductType(productType)"  :class="checkProductType===productType? 'nowItem':''" v-for="productType in productTypes">{{ productType }}</span>
                    </div><!---->
                </div>
            </div>
        </div>
        <div class="search w1240 cneter">
            <div class="searclnav">
                <div class="searchleft">
                    <el-input placeholder="请输入搜索内容" v-model="searchData">
                        <el-button @click="filterBySearch()" slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                </div>
            </div>
        </div>
        <div class="productlist">
            <div v-for="product in productList" class="item">
                <div class="left"><img :src="product.imgUrl" :title="product.productName"></div>
                <div class="center">
                    <h3 :title="product.productName">{{ product.productName }}</h3>
                    <div class="tag-list"><span v-for="tag in product.tags" class="tag"><i data-v-3bc735b0=""
                                class="el-icon-success icon-tag"></i>{{ tag }}</span></div>
                    <div data-v-3bc735b0=""><span data-v-3bc735b0=""> 限投份数： {{ product.qty }} 份</span><span
                            data-v-3bc735b0="">限投年龄：
                            {{ product.minAge }}-{{ product.maxAge }}岁</span></div>
                    <div class="center4"><span class="explain h42">{{ product.explain }}</span>
                    </div>
                </div>
                <div class="right">
                    <div class="price" style="margin-left: 32px;"> ￥{{ product.premium }}<span data-v-3bc735b0=""
                            class="tag">起</span></div><el-button type="primary" class="see">查看与投保</el-button>
                    <div style="margin-left: 32px; font-size: 12px;"> 已售<span data-v-3bc735b0=""
                            style="display: none;">56798</span><span data-v-3bc735b0="">{{ product.saled>=9999?'9999+':product.saled }}</span>份 </div>
                </div>
            </div>
            <div class="center pag">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>

        </div>
    </div>
</template>

<script>

export default {
    name: "Product",
    methods: {
        handleSizeChange(val) {
            this.pageSize = val;
            console.log(val)
            this.getCurrentPageData();
        },
        handleCurrentChange(val) {
            this.currentPage=val
            console.log(`当前页: ${val}`);
            console.log(val)
            this.getCurrentPageData();
        },
        getCurrentPageData() {
            let startIndex = (this.currentPage - 1) * this.pageSize;
            let endIndex = startIndex + this.pageSize;
            let tempProductList=this.allProductList;
            if(this.checkInsuranceType!='全部'&&this.checkInsuranceType!=''){
                tempProductList = tempProductList.filter(p=>p.insuranceType===this.checkInsuranceType)
            }
            if(this.checkInsuranceCompany!='全部'&&this.checkInsuranceCompany!=''){
                tempProductList = tempProductList.filter(p=>p.insuranceCompany===this.checkInsuranceCompany)
            }
            if(this.checkProductType!='全部'&&this.checkProductType!=''){
                tempProductList = tempProductList.filter(p=>p.productType===this.checkProductType)
            }
            if(this.searchData!=''){
                tempProductList = tempProductList.filter(p=>p.productName.indexOf(this.searchData)>-1);
            }
            if(startIndex>tempProductList.length){
                return;
            }
            if(endIndex>tempProductList.length){
                endIndex=tempProductList.length
            }
            this.total=tempProductList.length;
            this.productList=tempProductList.slice(startIndex,endIndex)
        },
        filterInsuranceType(val){
            this.checkInsuranceType=val;
            this.currentPage=1;
            this.getCurrentPageData()
        },
        filterInsuranceCompany(val){
            this.checkInsuranceCompany=val;
            this.currentPage=1;
            this.getCurrentPageData()
        },
        filterProductType(val){
            this.checkProductType=val;
            this.currentPage=1;
            this.getCurrentPageData()
        },
        filterBySearch(){
            this.currentPage=1;
            this.getCurrentPageData()
        },
        clear(){
            this.checkInsuranceType='全部'
            this.checkInsuranceCompany='全部'
            this.checkProductType='全部'
            this.searchData=''
            this.getCurrentPageData()
        }
    },
    created() {
        this.getCurrentPageData()
    },
    data() {
        return {
            currentPage: 1,
            pageSize: 10,
            total: 20,
            insuranceTypes: ["航意险", "团意险", "雇主险", "安责险", "货运险", "个人意外", "旅游"],
            insuranceCompanys: ["众安保险"],
            productTypes: ["个人", "企业", "企业+员工"],
            checkInsuranceType:"全部",
            checkInsuranceCompany:"全部",
            checkProductType:"全部",
            searchData:"",
            productList: [],

            allProductList: [
                {
                    productName: "813交通工具意外伤害保险计划十九",
                    premium: 100,
                    saled: 9999,
                    imgUrl: require('../assets/img/product/hyx19.png'),
                    insuranceType: "航意险",
                    insuranceCompany: "众安保险",
                    productType: "个人",
                    qty: 5,
                    minAge: 1,
                    maxAge: 65,
                    tags: ["精英版", "航意险", "绝佳伴侣"],
                    explain: "“空中飞人”绝佳伴侣"
                },
                {
                    productName: "813交通工具意外伤害保险计划十八",
                    premium: 80,
                    saled: 9999,
                    imgUrl: require('../assets/img/product/hyx18.png'),
                    insuranceType: "航意险",
                    insuranceCompany: "众安保险",
                    productType: "个人",
                    qty: 5,
                    minAge: 1,
                    maxAge: 65,
                    tags: ["精英版", "航意险", "绝佳伴侣"],
                    explain: "“空中飞人”绝佳伴侣"
                },
                {
                    productName: "813交通工具意外伤害保险计划十七",
                    premium: 80,
                    saled: 9999,
                    imgUrl: require('../assets/img/product/hyx17.png'),
                    insuranceType: "航意险",
                    insuranceCompany: "众安保险",
                    productType: "个人",
                    qty: 5,
                    minAge: 1,
                    maxAge: 65,
                    tags: ["精英版", "航意险", "绝佳伴侣"],
                    explain: "“空中飞人”绝佳伴侣"
                },
                {
                    productName: "813交通工具意外伤害保险计划十六",
                    premium: 80,
                    saled: 9999,
                    imgUrl: require('../assets/img/product/hyx16.png'),
                    insuranceType: "航意险",
                    insuranceCompany: "众安保险",
                    productType: "个人",
                    qty: 5,
                    minAge: 1,
                    maxAge: 65,
                    tags: ["精英版", "航意险", "绝佳伴侣"],
                    explain: "“空中飞人”绝佳伴侣"
                },
                {
                    productName: "813交通工具意外伤害保险计划十五",
                    premium: 60,
                    saled: 9999,
                    imgUrl: require('../assets/img/product/hyx15.png'),
                    insuranceType: "航意险",
                    insuranceCompany: "众安保险",
                    productType: "个人",
                    qty: 5,
                    minAge: 1,
                    maxAge: 65,
                    tags: ["精英版", "航意险", "绝佳伴侣"],
                    explain: "“空中飞人”绝佳伴侣"
                },
                {
                    productName: "813交通工具意外伤害保险计划十四",
                    premium: 60,
                    saled: 9999,
                    imgUrl: require('../assets/img/product/hyx14.png'),
                    insuranceType: "航意险",
                    insuranceCompany: "众安保险",
                    productType: "个人",
                    qty: 5,
                    minAge: 1,
                    maxAge: 65,
                    tags: ["精英版", "航意险", "绝佳伴侣"],
                    explain: "“空中飞人”绝佳伴侣"
                },
                {
                    productName: "813交通工具意外伤害保险计划十三",
                    premium: 60,
                    saled: 9999,
                    imgUrl: require('../assets/img/product/hyx13.png'),
                    insuranceType: "航意险",
                    insuranceCompany: "众安保险",
                    productType: "个人",
                    qty: 5,
                    minAge: 1,
                    maxAge: 65,
                    tags: ["精英版", "航意险", "绝佳伴侣"],
                    explain: "“空中飞人”绝佳伴侣"
                },
                {
                    productName: "813交通工具意外伤害保险计划十二",
                    premium: 50,
                    saled: 9999,
                    imgUrl: require('../assets/img/product/hyx12.png'),
                    insuranceType: "航意险",
                    insuranceCompany: "众安保险",
                    productType: "个人",
                    qty: 5,
                    minAge: 1,
                    maxAge: 65,
                    tags: ["精英版", "航意险", "绝佳伴侣"],
                    explain: "“空中飞人”绝佳伴侣"
                },
                {
                    productName: "813交通工具意外伤害保险计划十一",
                    premium: 50,
                    saled: 9999,
                    imgUrl: require('../assets/img/product/hyx11.png'),
                    insuranceType: "航意险",
                    insuranceCompany: "众安保险",
                    productType: "个人",
                    qty: 5,
                    minAge: 1,
                    maxAge: 65,
                    tags: ["精英版", "航意险", "绝佳伴侣"],
                    explain: "“空中飞人”绝佳伴侣"
                },
                {
                    productName: "813交通工具意外伤害保险计划十",
                    premium: 50,
                    saled: 9999,
                    imgUrl: require('../assets/img/product/hyx10.png'),
                    insuranceType: "航意险",
                    insuranceCompany: "众安保险",
                    productType: "个人",
                    qty: 5,
                    minAge: 1,
                    maxAge: 65,
                    tags: ["精英版", "航意险", "绝佳伴侣"],
                    explain: "“空中飞人”绝佳伴侣"
                },
                {
                    productName: "813交通工具意外伤害保险计划九",
                    premium: 50,
                    saled: 9999,
                    imgUrl: require('../assets/img/product/hyx9.png'),
                    insuranceType: "航意险",
                    insuranceCompany: "众安保险",
                    productType: "个人",
                    qty: 5,
                    minAge: 1,
                    maxAge: 65,
                    tags: ["精英版", "航意险", "绝佳伴侣"],
                    explain: "“空中飞人”绝佳伴侣"
                },
                {
                    productName: "813交通工具意外伤害保险计划八",
                    premium: 40,
                    saled: 9999,
                    imgUrl: require('../assets/img/product/hyx8.jpg'),
                    insuranceType: "航意险",
                    insuranceCompany: "众安保险",
                    productType: "个人",
                    qty: 5,
                    minAge: 1,
                    maxAge: 65,
                    tags: ["精英版", "航意险", "绝佳伴侣"],
                    explain: "“空中飞人”绝佳伴侣"
                },
                {
                    productName: "813交通工具意外伤害保险计划七",
                    premium: 40,
                    saled: 9999,
                    imgUrl: require('../assets/img/product/hyx7.png'),
                    insuranceType: "航意险",
                    insuranceCompany: "众安保险",
                    productType: "个人",
                    qty: 5,
                    minAge: 1,
                    maxAge: 65,
                    tags: ["精英版", "航意险", "绝佳伴侣"],
                    explain: "“空中飞人”绝佳伴侣"
                },
                {
                    productName: "813交通工具意外伤害保险计划六",
                    premium: 30,
                    saled: 9999,
                    imgUrl: require('../assets/img/product/hyx6.png'),
                    insuranceType: "航意险",
                    insuranceCompany: "众安保险",
                    productType: "个人",
                    qty: 5,
                    minAge: 1,
                    maxAge: 65,
                    tags: ["精英版", "航意险", "绝佳伴侣"],
                    explain: "“空中飞人”绝佳伴侣"
                },
                {
                    productName: "813交通工具意外伤害保险计划五",
                    premium: 30,
                    saled: 9999,
                    imgUrl: require('../assets/img/product/hyx5.png'),
                    insuranceType: "航意险",
                    insuranceCompany: "众安保险",
                    productType: "个人",
                    qty: 5,
                    minAge: 1,
                    maxAge: 65,
                    tags: ["精英版", "航意险", "绝佳伴侣"],
                    explain: "“空中飞人”绝佳伴侣"
                },
                {
                    productName: "813交通工具意外伤害保险计划四",
                    premium: 30,
                    saled: 9999,
                    imgUrl: require('../assets/img/product/hyx4.png'),
                    insuranceType: "航意险",
                    insuranceCompany: "众安保险",
                    productType: "个人",
                    qty: 5,
                    minAge: 1,
                    maxAge: 65,
                    tags: ["精英版", "航意险", "绝佳伴侣"],
                    explain: "“空中飞人”绝佳伴侣"
                },
                {
                    productName: "813交通工具意外伤害保险计划三",
                    premium: 20,
                    saled: 9999,
                    imgUrl: require('../assets/img/product/hyx3.png'),
                    insuranceType: "航意险",
                    insuranceCompany: "众安保险",
                    productType: "个人",
                    qty: 5,
                    minAge: 1,
                    maxAge: 65,
                    tags: ["精英版", "航意险", "绝佳伴侣"],
                    explain: "“空中飞人”绝佳伴侣"
                },
                {
                    productName: "813交通工具意外伤害保险计划二",
                    premium: 20,
                    saled: 9999,
                    imgUrl: require('../assets/img/product/hyx2.png'),
                    insuranceType: "航意险",
                    insuranceCompany: "众安保险",
                    productType: "个人",
                    qty: 5,
                    minAge: 1,
                    maxAge: 65,
                    tags: ["精英版", "航意险", "绝佳伴侣"],
                    explain: "“空中飞人”绝佳伴侣"
                },
                {
                    productName: "813交通工具意外伤害保险计划一",
                    premium: 20,
                    saled: 9999,
                    imgUrl: require('../assets/img/product/hyx1.png'),
                    insuranceType: "航意险",
                    insuranceCompany: "众安保险",
                    productType: "个人",
                    qty: 5,
                    minAge: 1,
                    maxAge: 65,
                    tags: ["精英版", "航意险", "绝佳伴侣"],
                    explain: "“空中飞人”绝佳伴侣"
                },
                {
                    productName: "众安雇主责任险",
                    premium: 100,
                    saled: 2,
                    imgUrl: require('../assets/img/product/zagz.jpg'),
                    insuranceType: "雇主险",
                    insuranceCompany: "众安保险",
                    productType: "企业+员工",
                    qty: 2,
                    minAge: 16,
                    maxAge: 65,
                    tags: ["可扩展24小时", "线上保全", "法律费用保障"],
                    explain: "1-4类意外伤害保额最高120万，5类保额最高80万"
                },
            ]
        };
    }
}

</script>

<style lang="scss" scoped>
.content {
    margin-top: 1rem;
    font-size: 14px;
    // width: 80%;
    color: #999;
    background-color: #fff;
    flex-wrap: wrap;

    .option {
        width: max(80%,900px);
    }

    .optionnav {
        width: 100%;
        height: 70px;
        line-height: 70px;
        background-color: #fbfbfa;
        display: flex;
        justify-content: space-between;

        span {
            display: inline-block;
            color: #9a9691;
            font-weight: 800;
            margin: 0 14px;
        }
        .right{
            cursor: pointer;
        }
        .right:hover{
            color: #00a0e9;
        }
    }

    .optioncontent {
        padding: 0 14px;

        .item {
            display: flex;

            font-size: 12px;
            margin-top: 20px;

            span {
                margin-left: 24px;
                cursor: pointer;
            }

            span:hover{
                color: #00a0e9;
            }

            .sortList {
                display: flex;
                flex-flow: wrap;
                height: 18px;

                .nowItem {
                    color: #00a0e9
                }

            }
        }
    }

    .search {
        margin-top: 22px;
        width: max(80%,900px);

        .searclnav {
            height: 70px;
            background-color: #fbfbfa;
            font-size: 14px;
            display: flex;
            align-items: center;

            .searchleft {
                width: 400px;
                margin-left: 10px;
            }
        }
    }

    .productlist {
        width: max(80%,900px);

        .item {
            height: 200px;
            margin-top: 30px;
            border-bottom: 1px solid #dddbdb;
            cursor: pointer;
            display: flex;
            flex: 1;
            justify-content: space-between;

            .left {

                img {
                    width: 230px;
                    height: 170px;
                    margin-left: 10px;
                }
            }

            .center {
                margin-left: 30px;
                width: calc(100% - 500px);
                display: block;

                div {
                    margin-top: 14px;
                    font-size: 14px;

                    span {
                        margin-right: 42px;
                    }
                }

                h3 {
                    width: 550px;
                    color: #525252;
                    font-size: 24px;
                    margin-top: 10px;
                    font-weight: 400;
                    cursor: pointer;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                .tag-list {
                    color: #666;

                    .tag {
                        min-width: 80px;
                        background-color: rgba(0, 160, 233, .1);
                        padding: 2px 10px;
                        border: 1px solid rgba(0, 160, 233, .2);
                        border-radius: 4px;
                        color: #acacac;
                        margin-right: 10px;
                        font-size: 12px;

                        .icon-tag {
                            margin-right: 5px;
                            color: #00a0e9;
                        }
                    }
                }

                .center4 {
                    color: #00a0e9;
                }
            }

            .right {
                margin-top: 36px;
                height: 142px;
                padding-left: 60px;
                padding-right: 60px;
                border-left: 1px solid #dddbdb;

                .price {
                    color: #00a0e9;
                    font-size: 28px;
                    margin-left: 0 !important;
                    text-align: center;
                    position: relative;

                    span {
                        font-size: 12px;
                        margin-top: 18px;
                    }
                }

                .see {
                    width: 126px;
                    margin: 12px 0 12px 0;
                    background-color: #00a0e9;
                    font-size: 12px;
                    border-radius: 5px;
                }
            }
        }

        .pag {
            margin: 20px 0;
        }
    }

}

.center {
    display: flex;
    justify-content: center;
}
</style>